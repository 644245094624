import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'
import { Hero } from '../organisms/Hero'
import { JumbotronProps } from '../organisms/Jumbotron'
import { getSections, Section } from './Generic'
export interface HomeProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    sections: Section[]
}

export const Home: React.FC<HomeProps> = (props) => {
    const { jumbotron, layout, sections } = props

    return (
        <Layout {...layout} noBreadcrumbs>
            <Hero {...jumbotron} />
            {getSections(sections)}
        </Layout>
    )
}
