import { RouteComponentProps } from '@reach/router'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { Home } from 'common/components/templates/Home'
import { getLayoutData } from 'common/utils/getLayoutData'
import { VERSION } from 'core/config/env'
import React from 'react'

export interface HomePageProps extends RouteComponentProps {
    pageContext: any
}

export const HomePage: React.FC<HomePageProps> = ({ pageContext }) => {
    return <Home {...getHomeData(pageContext)} />
}
console.log(`v${VERSION}`)

export default HomePage

export const getHomeData = (data: any) => {
    return {
        jumbotron: getJumbotronData(data?.hero?.[0]),
        layout: getLayoutData(data),
        sections: data.sections,
    }
}
